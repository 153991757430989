import './hosting.scss';
import './postPage.scss';
import axios from 'axios';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import CommentModal from './coment/comment';
import CommentIcon from './image/commentIcon.png';
import ActiveLike from './image/activeLike.png';
import UnactiveLike from './image/unactiveLike.png';
import { formatDistanceToNow } from 'date-fns';

function CustomVideoPlayer({ src, type }) {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [showControls, setShowControls] = useState(false);

    const togglePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
        videoRef.current.muted = !isMuted;
    };

    return (
        <div
            className="custom-video"
            onMouseEnter={() => setShowControls(true)}
            onMouseLeave={() => setShowControls(false)}
        >
            <video ref={videoRef} muted={isMuted} onClick={togglePlayPause} loop>
                <source src={src} type={type} />
                Your browser does not support the video tag.
            </video>
            <div className={`video-controls ${showControls ? 'visible' : ''}`}>
                <button className="play-pause" onClick={togglePlayPause}>
                    {isPlaying ? '❚❚' : '►'}
                </button>
                <button className="volume" onClick={toggleMute}>
                    {isMuted ? '🔇' : '🔊'}
                </button>
            </div>
        </div>
    );
}

function Hosting({ type }) {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [commentPostId, setCommentPostId] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [expandedPosts, setExpandedPosts] = useState([]);
    const observer = useRef();

    const fetchPosts = useCallback(async (skip = 0) => {
        try {
            const token = localStorage.getItem('jwtToken');
            const res = await axios.get('/api/posts', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    skip,
                    limit: 10,
                    type
                }
            });

            setPosts(prevPosts => {
                const newPosts = [...prevPosts, ...res.data.posts];
                const uniquePosts = Array.from(new Set(newPosts.map(post => post.id)))
                    .map(id => {
                        return newPosts.find(post => post.id === id)
                    });
                return uniquePosts;
            });
            setLoading(false);

            if (res.data.posts.length === 0) {
                setHasMore(false);
            }
        } catch (error) {
            console.log(error);
        }
    }, [type]);

    useEffect(() => {
        setPosts([]);
        setLoading(true);
        fetchPosts();
    }, [fetchPosts]);

    const lastPostElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                fetchPosts(posts.length);
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore, fetchPosts, posts.length]);

    const handleLike = async (postId, liked) => {
        try {
            const token = localStorage.getItem('jwtToken');
            const url = liked ? `/api/unlike/${postId}` : `/api/like/${postId}`;
    
            await axios.post(url, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            setPosts((prevPosts) => {
                return prevPosts.map((post) =>
                    post.id === postId
                        ? { ...post, liked: !liked, countLike: liked ? post.countLike - 1 : post.countLike + 1 }
                        : post
                );
            });
        } catch (error) {
            console.error(error);
        }
    };

    const openComments = (postId) => {
        setCommentPostId(postId);
    };

    const closeComments = () => {
        setCommentPostId(null);
    };

    const toggleExpandPost = (postId) => {
        setExpandedPosts((prevExpandedPosts) =>
            prevExpandedPosts.includes(postId)
                ? prevExpandedPosts.filter(id => id !== postId)
                : [...prevExpandedPosts, postId]
        );
    };

    if (loading) {
        return <h1>Загрузка материала</h1>;
    }

    if (!posts.length) {
        return <h1>Нет постов</h1>;
    }

    return (
        <div className="Hosting">
            {posts.map((post, index) => (
                <div
                    key={post.id}
                    ref={index === posts.length - 1 ? lastPostElementRef : null}
                >
                    <div className="postPage">
                        <div className="postPage-header">
                            <div className="postHeader-login">
                                <div className="postHeader-loginContainer">
                                    <img src={post.user.avatarUrl} alt="User Avatar" />
                                </div>
                                <Link to={`/userProfile/${post.user.id}`}>
                                    <span>{post.user.login}</span>
                                </Link>
                            </div>
                            <div></div>
                        </div>
                        <div className="postPage-main">
                            {post.fileType.startsWith('image') ? (
                                <img src={post.postUrl} alt="Post Image" />
                            ) : post.fileType.startsWith('video') ? (
                                <CustomVideoPlayer src={post.postUrl} type={post.fileType} />
                            ) : null}
                        </div>

                        <div className="postPage-footer">
                            <div className="pageFooter-countsInf">
                                <nav>
                                    <img
                                        onClick={() => handleLike(post.id, post.liked)}
                                        src={post.liked ? ActiveLike : UnactiveLike}
                                        alt="Like Icon"
                                    />
                                    <span>{post.countLike}</span>
                                </nav>
                                <nav>
                                    <img src={CommentIcon} alt="Comment Icon" onClick={() => openComments(post.id)} />
                                    <span>{post.countComment}</span>
                                </nav>
                            </div>

                            <div className="pageFooter-underText">
                                <p
                                    className='pageFooter-paragraph'
                                    onClick={() => toggleExpandPost(post.id)}
                                    style={{
                                        overflow: expandedPosts.includes(post.id) ? 'visible' : 'hidden',
                                        whiteSpace: expandedPosts.includes(post.id) ? 'normal' : 'nowrap',
                                        textOverflow: expandedPosts.includes(post.id) ? 'clip' : 'ellipsis'
                                    }}
                                >
                                    {post.description}
                                </p>
                                <p className="postDate">
                                    {isNaN(new Date(post.createdAt)) ? 'Invalid date' : formatDistanceToNow(new Date(post.createdAt), { addSuffix: true })}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {commentPostId && (
                <CommentModal postId={commentPostId} onClose={closeComments} />
            )}
        </div>
    );
}

export default Hosting;
