import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import useRoutes from './routes/route';
import { useEffect } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

function App() {
  const route = useRoutes();

  useEffect(() => {
    async function registerServiceWorkerAndSubscribe() {
      if ('serviceWorker' in navigator) {
        try {
          // Регистрация Service Worker
          const registration = await navigator.serviceWorker.register('/sw.js');
          console.log('Service Worker зарегистрирован:', registration);

          // Запрос разрешения на уведомления
          const permission = await Notification.requestPermission();
          if (permission !== 'granted') {
            console.warn('Пользователь отклонил разрешение на уведомления');
            return;
          }

          console.log('Разрешение на уведомления получено');

          // Подписка на Push
          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
          });

          console.log('Подписка на push уведомления:', subscription);

          // Извлечение токена из localStorage
          const token = localStorage.getItem('jwtToken');
          if (!token) {
            console.warn('Токен авторизации отсутствует');
            return;
          }

          // Декодирование токена для получения userId
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.id;

          // Отправка подписки на сервер
          await axios.post('/api/subscribePush', {
            userId,
            subscription,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          console.log('Подписка отправлена на сервер');
        } catch (error) {
          console.error('Ошибка при настройке push уведомлений:', error);
        }
      }
    }

    registerServiceWorkerAndSubscribe();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        {route}
      </div>
    </BrowserRouter>
  );
}

export default App;
