import React, { useEffect, useState } from 'react';
import axios from 'axios';

function UserCount() {
    const [userCount, setUserCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchUserCount = async () => {
            try {
                const response = await axios.get('/api/users/count');
                setUserCount(response.data.count);
            } catch (error) {
                setError('Ошибка при получении количества пользователей');
            } finally {
                setLoading(false);
            }
        };

        fetchUserCount();
    }, []);

    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <h1>Количество пользователей на сайте: {userCount}</h1>
        </div>
    );
}

export default UserCount;
