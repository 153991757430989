import './auth.scss';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

function Auth() {
    const [form, setForm] = useState({
        login: '',
        password: '',
    });

    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const goHome = () => navigate('/', { replace: true });

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const authHandler = async () => {
        try {
            const response = await axios.post('/api/auth', { ...form }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const token = response.data.token;
            localStorage.setItem('jwtToken', token);
            goHome();
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message); // Устанавливаем сообщение об ошибке
            } else {
                setErrorMessage('Ошибка при подключении к серверу');
            }
        }
    };

    return (
        <div className='auth-container'>
            <div className='auth-form-container'>
                <div className='auth-logo'>
                    <nav><span>SF</span></nav>
                </div>
                <form className='auth-form' method='post' onClick={e => e.preventDefault()}>
                    <div className="auth-inputs">
                        <input type="text" name='login' onChange={changeHandler} placeholder='Имя пользователя' />
                        <input type="password" name='password' onChange={changeHandler} placeholder='Пароль' />
                    </div>
                    <button type='submit' onClick={authHandler} className='auth-button'>Войти</button>
                </form>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <div className='auth-link-container'>
                    <p>Нет аккаунта? <Link to='/register' className='auth-link'>Зарегистрироваться</Link></p>
                </div>
            </div>
        </div>
    );
}

export default Auth;
