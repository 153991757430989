import { useState, useEffect } from "react";
import axios from "axios";
import StoryViewer from "../storyViewer/storyViewer";
import StoryUpload from "../storyUpload/storyUpload";
import MyStoryViewer from "../myStories/myStories";
import "./storyList.scss";

const StoryList = () => {
  const [stories, setStories] = useState([]); // Сторисы подписчиков
  const [myStories, setMyStories] = useState(null); // Ваши сторисы
  const [viewedStories, setViewedStories] = useState(new Set()); // Просмотренные сторисы
  const [currentStoryIndex, setCurrentStoryIndex] = useState(null);
  const [isViewerOpen, setViewerOpen] = useState(false);
  const [isUploadOpen, setUploadOpen] = useState(false);
  const [isMyStoryViewerOpen, setMyStoryViewerOpen] = useState(false); // Управление MyStoryViewer
  const [loading, setLoading] = useState(true);

  // Загрузка сторисов и просмотренных ID
  useEffect(() => {
    const fetchStories = async () => {
      try {
        const token = localStorage.getItem("jwtToken");
        const headers = { Authorization: `Bearer ${token}` };

        const [storiesData, myData, viewedData] = await Promise.all([
          axios.get("/api/stories/subscribers", { headers }),
          axios.get("/api/myStory", { headers }),
          axios.get("/api/stories/viewed", { headers }),
        ]);

        setStories(storiesData.data);
        setMyStories(myData.data);
        setViewedStories(new Set(viewedData.data)); // Устанавливаем просмотренные сторисы
      } catch (error) {
        console.error("Error fetching stories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStories();
  }, []);

  // Функция для регистрации просмотра
  const registerStoryView = async (storyId) => {
    try {
      console.log(storyId);
      const token = localStorage.getItem("jwtToken");
      await axios.post(
        "/api/stories/view",
        { storyId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setViewedStories((prev) => new Set(prev).add(storyId)); // Локально обновляем статус
    } catch (error) {
      console.error("Error registering story view:", error);
    }
  };

  // Функция для удаления сториса
  const deleteStory = async (storyId) => {
    try {
      const token = localStorage.getItem("jwtToken");
      await axios.delete(`/api/story/${storyId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Обновляем локальное состояние после удаления
      setMyStories((prev) => ({
        ...prev,
        myStories: prev.myStories.filter((story) => story.id !== storyId),
      }));
      console.log(`Сторис с ID ${storyId} успешно удален.`);
    } catch (error) {
      console.error("Ошибка при удалении сториса:", error);
    }
  };

  const openStoryViewer = (storyGroup, index) => {
    setCurrentStoryIndex({ group: storyGroup, index });
    setViewerOpen(true);
  };

  const closeStoryViewer = () => {
    setViewerOpen(false);
    setCurrentStoryIndex(null);
  };

  const goToNextStory = () => {
    if (!currentStoryIndex) {
      console.error("currentStoryIndex не инициализирован.");
      return;
    }

    const { index: userIndex, group, storyIndex = 0 } = currentStoryIndex;

    const currentStories =
      group === "myStories"
        ? myStories?.myStories[userIndex]?.stories
        : stories[userIndex]?.stories;

    if (!currentStories || currentStories.length === 0) {
      console.error("Истории отсутствуют.");
      closeStoryViewer();
      return;
    }

    const storyId = currentStories[storyIndex]?.id;
    console.log("Полученный storyId:", storyId);

    if (!storyId) {
      console.error("storyId не найден. Проверьте данные.");
      return;
    }

    registerStoryView(storyId);

    if (storyIndex < currentStories.length - 1) {
      setCurrentStoryIndex({
        group,
        index: userIndex,
        storyIndex: storyIndex + 1,
      });
    } else if (
      userIndex <
      (group === "myStories" ? myStories.myStories.length : stories.length) - 1
    ) {
      setCurrentStoryIndex({
        group,
        index: userIndex + 1,
        storyIndex: 0,
      });
    } else {
      closeStoryViewer();
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="story-list">
      {/* Сторисы текущего пользователя */}
      {console.log(viewedStories)}
      <div className="story-item">
        <div className="avatar-container">
          <img
            src={myStories?.user.logoImage}
            alt="Your story"
            className={myStories && myStories.myStories?.length > 0 ? "new-story" : ""}
            onClick={() => setMyStoryViewerOpen(true)}
          />
          <button className="add-story1" onClick={() => setUploadOpen(true)}>
            +
          </button>
        </div>
        <span>Вы</span>
      </div>

      {/* Сторисы подписчиков */}
      {stories.map((story, index) => (
        <div
          key={index}
          className="story-item"
          onClick={() => openStoryViewer("subscriberStories", index)}
        >
          <div className="avatar-container">
            <img
              src={story.logoImage}
              alt={story.login}
              className={story.stories.some((s) => !viewedStories.has(s.id))
                ? "not-viewed"
                : "viewed"}
            />
          </div>
          <span>{story.login}</span>
        </div>
      ))}

      {/* MyStoryViewer */}
      {isMyStoryViewerOpen && myStories && (
        <MyStoryViewer
          stories={myStories.myStories}
          login={myStories.user.login}
          logoImage={myStories.user.logoImage}
          onClose={() => setMyStoryViewerOpen(false)}
          onDeleteStory={deleteStory} // Передаем функцию удаления
        />
      )}

      {/* StoryViewer */}
      {isViewerOpen && currentStoryIndex && (
        <StoryViewer
          stories={
            currentStoryIndex.group === "myStories"
              ? myStories.myStories
              : stories[currentStoryIndex.index].stories
          }
          login={
            currentStoryIndex.group === "myStories"
              ? myStories.user.login
              : stories[currentStoryIndex.index].login
          }
          logoImage={
            currentStoryIndex.group === "myStories"
              ? myStories.user.logoImage
              : stories[currentStoryIndex.index].logoImage
          }
          onClose={closeStoryViewer}
          onNext={goToNextStory}
        />
      )}

      {/* StoryUpload */}
      {isUploadOpen && <StoryUpload onClose={() => setUploadOpen(false)} />}
    </div>
  );
};

export default StoryList;
