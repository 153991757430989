import Header from "../../components/header/header";
import Nav from "../../components/navElements/nav";
import Hosting from "../../components/hosting/Hosting/hosting";
import StoryList from "../../components/storyes/storyList/storyList";

function MainPage() {
    return (
        <>
            <Header/>
            <StoryList/>
            <Nav/>
            <Hosting/>
        </>
    )
}

export default MainPage;