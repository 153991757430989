import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './MyStories.scss';

const MyStories = ({ stories, userId, login, logoImage, onClose, onNext, onPrevious, onDeleteStory }) => {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [progress, setProgress] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [storyToDelete, setStoryToDelete] = useState(null);
  const [isPaused, setIsPaused] = useState(false); // Для паузы при зажатии
  const navigate = useNavigate();
  const intervalRef = useRef(null); // Ссылка на интервал

  const currentStory = stories[currentStoryIndex];

  useEffect(() => {
    if (!currentStory || isPaused) return;

    const totalStories = stories.length;
    const initialProgress = new Array(totalStories).fill(0);
    setProgress(initialProgress);

    const storyDuration = currentStory.fileType.startsWith('image') ? 5000 : currentStory.duration || 5000;
    const updateInterval = 100; 
    const progressStep = (100 / (storyDuration / updateInterval));

    intervalRef.current = setInterval(() => {
      setProgress((prev) => {
        const updatedProgress = [...prev];
        updatedProgress[currentStoryIndex] += progressStep;

        if (updatedProgress[currentStoryIndex] >= 100) {
          clearInterval(intervalRef.current);
          handleNextStory();
        }

        return updatedProgress;
      });
    }, updateInterval);

    return () => clearInterval(intervalRef.current);
  }, [currentStoryIndex, stories, isPaused]);

  const handleNextStory = () => {
    if (currentStoryIndex < stories.length - 1) {
      setCurrentStoryIndex((prevIndex) => prevIndex + 1);
    } else {
      onClose();
    }
  };

  const handlePreviousStory = () => {
    if (currentStoryIndex > 0) {
      setCurrentStoryIndex((prevIndex) => prevIndex - 1);
    } else {
      onPrevious();
    }
  };

  const handlePause = () => {
    clearInterval(intervalRef.current);
    setIsPaused(true);
  };

  const handleResume = () => {
    setIsPaused(false);
  };

  const handleContentClick = (e) => {
    const { clientX, currentTarget } = e;
    const { offsetWidth } = currentTarget;

    if (clientX < offsetWidth / 2) {
      handlePreviousStory();
    } else {
      handleNextStory();
    }
  };

  const handleDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
    setStoryToDelete(currentStory.id);
  };

  const handleDeleteStory = () => {
    if (storyToDelete) {
      onDeleteStory(storyToDelete);
      setShowDeleteConfirmation(false);
      handleNextStory();
    }
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const storyDate = new Date(timestamp);
    const diffInSeconds = Math.floor((now - storyDate) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} секунд назад`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} минут назад`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} часов назад`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} дней назад`;
    }
  };

  if (!currentStory) {
    return null;
  }

  return (
    <div className="myStories-viewer">
      <div className="myStories-header">
        <div className="myStories-user-info">
          <img src={logoImage} alt={login} className="myStories-avatar" />
          <div className="myStories-user-details">
            <span className="myStories-login">{login}</span>
            <span className="myStories-time-ago">{getTimeAgo(currentStory.createdAt)}</span>
          </div>
        </div>
        <div className="myStories-actions">
          <button
            className="myStories-delete-btn"
            onClick={handleDeleteConfirmation}
          >
            •••
          </button>
          <button className="myStories-close-btn" onClick={onClose}>✕</button>
        </div>
      </div>

      <div className="myStories-progress-bar">
        {stories.map((_, index) => (
          <div
            key={index}
            className={`myStories-progress-segment ${index === currentStoryIndex ? 'active' : ''}`}
            style={{
              width: `${100 / stories.length}%`,
              background: progress[index] >= 100 ? 'white' : 'rgba(255, 255, 255, 0.3)',
            }}
          >
            <div
              className="myStories-progress-fill"
              style={{
                width: `${Math.min(progress[index], 100)}%`,
                background: 'white',
              }}
            ></div>
          </div>
        ))}
      </div>

      <div
        className="myStories-content"
        onClick={handleContentClick}
        onMouseDown={handlePause}
        onMouseUp={handleResume}
        onTouchStart={handlePause}
        onTouchEnd={handleResume}
      >
        {currentStory.fileType.startsWith('image') ? (
          <img
            src={currentStory.fileUrl}
            alt="Story"
            className="myStories-media"
          />
        ) : currentStory.fileType.startsWith('video') ? (
          <video
            src={currentStory.fileUrl}
            className="myStories-media"
            autoPlay={!isPaused}
            onEnded={handleNextStory}
          ></video>
        ) : (
          <div>Unsupported file type</div>
        )}
      </div>

      <div className="myStories-text">
        <p>{currentStory.text}</p>
      </div>

      {showDeleteConfirmation && (
        <div className="myStories-delete-confirmation">
          <p>Удалить эту сторис?</p>
          <button className="myStories-confirm-btn" onClick={handleDeleteStory}>
            Удалить
          </button>
          <button
            className="myStories-cancel-btn"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Отмена
          </button>
        </div>
      )}
    </div>
  );
};

export default MyStories;
