import React, { useState } from 'react';
import axios from 'axios';
import { FaCamera, FaFolderOpen } from 'react-icons/fa';
import './storyUpload.scss';

const StoryUpload = ({ onClose }) => {
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [text, setText] = useState('');
    const [error, setError] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setPreviewUrl(URL.createObjectURL(selectedFile));
        }
    };

    const openCamera = () => {
        alert('Функция открытия камеры пока не реализована');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!file) {
            setError('Выберите файл для загрузки');
            return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('text', text);
    
        try {
            const token = localStorage.getItem('jwtToken');
            await axios.post('/api/story/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                },
            });
            onClose();
        } catch (error) {
            console.error('Ошибка загрузки сториса:', error);
            setError('Ошибка при загрузке сториса');
        }
    };
    

    return (
        <>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="story-upload">
                <h2>Загрузить сторис</h2>
                {error && <p className="error">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="upload-buttons">
                        <label className="file-input-label">
                            <FaFolderOpen className="upload-icon" />
                            <input
                                type="file"
                                accept="image/*,video/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                        </label>
                        <button type="button" className="camera-button" onClick={openCamera}>
                            <FaCamera />
                        </button>
                    </div>
                    {previewUrl && (
                        file.type.startsWith('video') ? (
                            <video className="preview" src={previewUrl} controls />
                        ) : (
                            <img className="preview" src={previewUrl} alt="Превью" />
                        )
                    )}
                    <textarea
                        placeholder="Введите текст (необязательно)"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />
                    <button type="submit">Загрузить</button>
                </form>
                {uploadProgress > 0 && (
                    <div className="progress-bar">
                        <div
                            className="progress-bar-fill"
                            style={{ width: `${uploadProgress}%` }}
                        ></div>
                    </div>
                )}
            </div>
        </>
    );
};

export default StoryUpload;
